/* eslint-disable jsx-a11y/media-has-caption */
import { lazy } from 'react';
import { useLazyComponentPreload } from '@medifind/hooks';
import { usePageHooks } from '@medifind/router';
import { SEO } from '@medifind/seo';
import { MFLazyLoad } from '@medifind/shared-basic-components';
import { useAuthentication } from '@medifind/zustand';
import Landing from '../components/home/Landing';

const How = lazy(() => import('../components/home/How'));
const Why = lazy(() => import('../components/home/Why'));
const Tools = lazy(() => import('../components/home/Tools'));
const Signup = lazy(() => import('../components/home/Signup'));
const FeaturedIn = lazy(() => import('../components/home/FeaturedIn'));

/* __ROUTE_PATH__=/ */
const Index = ({ titleText = null, logo = null, searchBarPlaceholder = null }) => {
  usePageHooks();
  useLazyComponentPreload([How, Why, Tools, Signup, FeaturedIn]);
  const isLoggedIn = useAuthentication((state) => state?.profile);
  const desc =
    'When it comes to your health, nothing is more valuable than time. Quickly find the best doctors, latest medical advances, and active clinical trials with MediFind. Our goal is to dramatically reduce the amount of time and frustration you spend finding the right healthcare, so you have the best possible chances at the best health outcomes.';
  return (
    <>
      <SEO description={desc} />
      <Landing titleText={titleText} logo={logo} searchBarPlaceholder={searchBarPlaceholder} />

      <MFLazyLoad height={1600}>
        <Why />
      </MFLazyLoad>
      <MFLazyLoad height={600}>
        <FeaturedIn />
      </MFLazyLoad>
      <MFLazyLoad height={1000}>
        <How />
      </MFLazyLoad>
      <MFLazyLoad height={1000}>
        <Tools />
      </MFLazyLoad>
      <MFLazyLoad height={800}>
        <Signup hideSignupButton={!!isLoggedIn} />
      </MFLazyLoad>
    </>
  );
};

export default Index;
