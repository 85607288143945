import { useLayoutEffect } from 'react';
import { scrollToId } from '@medifind/utils';
import { useRouter } from './useRouter';

export const usePageHooks = (data) => {
  const router = useRouter();
  const dataChange = !!data;
  useLayoutEffect(() => {
    const hash = router.location.hash;
    if (hash) {
      scrollToId(hash.slice(1));
    }
  }, [router.location.hash, dataChange]);
  return null;
};
