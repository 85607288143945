/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useState } from 'react';
import { wpPostBySlug } from '@medifind/interface';
import { useRouter } from '@medifind/router';
import Index from './index';

/* __ROUTE_PATH__=/h/:slug */
const Landing = () => {
  const router = useRouter();
  const { slug } = router.query;
  const [fields, setFields] = useState(null);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    wpPostBySlug({ slug, getContent: true })
      .then((data) => {
        setFields(data ? data.acf : {});
      })
      .catch(() => {
        setNotFound(true);
      });
  }, [slug]);

  if (notFound) {
    throw new Error(404);
  }

  return <Index {...fields} />;
};

export default Landing;
