import { lazy } from 'react';
import { Suspense } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useLazyComponentPreload } from '@medifind/hooks';
import { useSearchParams } from '@medifind/router';
import { useMediaQueryUp } from '@medifind/utils';
import FindADoctorForm from './forms/find-a-doctor-form';
import styles from './Landing.module.scss';
const SymptomCheckerForm = lazy(() => import('./forms/symptom-checker-form'));
const SecondOpinionForm = lazy(() => import('./forms/second-opinion-form'));
const ConditionSearchForm = lazy(() => import('./forms/condition-search-form'));
const LatestAdvancesForm = lazy(() => import('./forms/latest-advances-form'));
const ClinicalTrialsForm = lazy(() => import('./forms/clinical-trials-form'));

const tabs = [
  {
    text: `Symptom Checker`,
    id: `symptom-checker`,
    src: `https://cdn.medifind.com/assets/journey-step-symptoms.svg`,
    width: 48,
    height: 59,
    background: `linear-gradient(225deg, #CD3758 0%, #E5243E 100%)`,
    color: '#CD3758',
    form: SymptomCheckerForm,
  },
  {
    text: `Condition Search`,
    id: `condition-search`,
    src: `https://cdn.medifind.com/assets/journey-step-condition.svg`,
    width: 44,
    height: 56,
    background: `linear-gradient(225deg, #B94B72 0%, #CD3758 100%)`,
    color: '#B94B72',
    form: ConditionSearchForm,
  },
  {
    text: `Find a Doctor`,
    id: `find-a-doctor`,
    src: `https://cdn.medifind.com/assets/journey-step-experts.svg`,
    width: 46,
    height: 56,
    background: `linear-gradient(225deg, #835692 0%, #A54F7E 100%)`,
    color: '#835692',
    form: FindADoctorForm,
  },
  {
    text: `Latest Advances`,
    id: `latest-advances`,
    src: `https://cdn.medifind.com/assets/journey-step-research.svg`,
    width: 45,
    height: 56,
    background: `linear-gradient(225deg, #595FAA 0%, #715A9C 100%)`,
    color: '#595FAA',
    form: LatestAdvancesForm,
  },
  {
    text: `Clinical Trials`,
    id: `clinical-trials`,
    src: `https://cdn.medifind.com/assets/journey-step-trials.svg`,
    width: 49,
    height: 56,
    background: `linear-gradient(225deg, #4062BB 0%, #595FAA 100%)`,
    color: '#4062BB',
    form: ClinicalTrialsForm,
  },
];
const Tab = ({ src, text, id, width, height, background, color, i, isSelected, onClick }) => {
  return (
    <button
      className={classNames(styles['tab'], { [styles['selected']]: isSelected })}
      style={{
        fontSize: 16,
        top: (2 - Math.floor(Math.abs(i - 2))) * 10, // For 6 items: ((2 - Math.floor(Math.abs(i - 2.5))) * 10)
        position: 'relative',
      }}
      onClick={() => onClick && onClick(id)}
    >
      <div
        style={{
          background: background,
          // backgroundImage: src
        }}
        className={classNames(styles['tab__icon'], { [styles['selected']]: isSelected })}
      >
        <img
          src={src}
          width={width}
          height={height}
          className={classNames(styles['tab__icon__img'], width / height < 1 ? styles['portrait'] : styles['landscape'])}
          alt={text}
        />
      </div>
      <div
        className={classNames(styles['tab__text'], { [styles['selected']]: isSelected })}
        style={{
          color: color,
        }}
      >
        {text}
      </div>
      <div className={classNames(styles['selector'], { [styles['selected']]: isSelected })} style={{ '--color': color }}></div>
    </button>
  );
};
const Landing = ({ titleText, logo }) => {
  const [{ tab: selectedTab = 'find-a-doctor' }, setSearchParams] = useSearchParams();
  const setSelectedTab = (value) => setSearchParams({ tab: value }, { replace: true, clearExistingParams: false });

  const mediumBreakpoint = useMediaQueryUp('lg');
  const selectedI = tabs.findIndex((tab) => tab.id === selectedTab);
  const { color, form: Form, src, text, background, width, height } = tabs[selectedI];

  useLazyComponentPreload([SymptomCheckerForm, SecondOpinionForm, ConditionSearchForm, LatestAdvancesForm, ClinicalTrialsForm], 4000);

  return (
    <div className={styles['landing']}>
      <div className={styles['landing-heading-container']}>
        <div className={styles['landing-heading']}>
          <h1 className={'hide-h-tag'}>MediFind - Better care. Faster.</h1>
          {titleText ? (
            <div className={styles['search-header']}>{titleText}</div>
          ) : (
            <>
              <div className={styles['search-header']}>When your health gets complicated and you don't know where to turn, we help you</div>
              <div className={classNames(styles['search-header'], styles['emphasis'])}>find better care, faster.</div>
            </>
          )}
        </div>
      </div>
      <div className={styles['tabs-container']}>
        <div className={styles['tabs']}>
          {tabs.map((tab, i) => (
            <Tab key={tab.id} {...tab} i={i} isSelected={tab.id === selectedTab} onClick={setSelectedTab} />
          ))}
        </div>
      </div>
      <div className={classNames(styles['search-bar'])}>
        <div
          style={{
            background: background,
          }}
          className={classNames(styles['search-bar__icon'])}
        >
          <img
            src={src}
            width={width * 1.25}
            height={height * 1.25}
            className={styles['search-bar__icon__img']}
            alt={text}
            loading="lazy"
          />
        </div>
        <div
          style={{
            borderColor: color,
          }}
          className={classNames(styles['search-bar__form'])}
        >
          <Suspense fallback={<div className="fallback" />}>
            <Form
              showWrapper={!mediumBreakpoint}
              heading={
                <div
                  style={{
                    color: color,
                  }}
                  className={styles['search-bar__heading']}
                >
                  {text}
                </div>
              }
              classes={{
                formContainer: styles['form-container'],
                formSeperator: styles['form-seperator'],
                formSubmitButton: styles['form-submit-button'],
                formInputText: classNames(styles['form-input-text'], styles['form-input-menu-wrapper']),
                formInput: styles['form-input'],
                formInputField: styles['form-input-field'],
                formSecondaryInputField: styles['form-secondary-input-field'],
                formField: styles['form-field'],
                formError: styles['form-error'],
                formDropdown: styles['form-field-dropdown'],
              }}
            />
          </Suspense>
        </div>
      </div>
    </div>
  );
};
Landing.propTypes = {
  titleText: PropTypes.string,
  logo: PropTypes.string,
};

export default Landing;
