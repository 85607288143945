import { changeURLParams } from './url';
import { useRouter } from './useRouter';

export const useSearchParams = () => {
  const router = useRouter();

  return [
    router.queryParams,
    (params, { replace = false, clearExistingParams = true }) =>
      changeURLParams(router, params, { type: replace ? 'replace' : 'push', clearExistingParams }),
  ];
};
