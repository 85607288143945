import { abortableFetch } from '../clientFetch';

export const locationAutocomplete = ({ input, countryCodes, lat, lon, types }) => {
  return abortableFetch(
    '/autocomplete/location',
    {
      params: {
        input,
        countryCodes,
        lat,
        lon,
        types,
      },
    },
    'locationAutocompleteController',
  );
};
