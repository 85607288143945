import { clientFetch } from '../clientFetch';

export const wpPostBySlug = ({ parentCategory, slug, articleId, projectId, getContent, getImages, getTags, getCategories, getAuthor }) => {
  return clientFetch('/wp/posts/slug', {
    params: {
      parentCategory,
      slug,
      getContent,
      getImages,
      getTags,
      getCategories,
      getAuthor,
      articleId,
      projectId,
    },
  });
};
